import dayjs from 'dayjs';

const LOCAL_STORAGE_KEY = 'coveo-access-token';

interface SavedAccessToken {
    token: string;
    expiration: number;
}

const defaults: SavedAccessToken = Object.freeze({
    token: '',
    expiration: 0,
});

const getSaved = (): SavedAccessToken => {
    const stored = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (stored === null) {
        // nothing stored
        return Object.assign({}, defaults);
    }

    try {
        const saved = JSON.parse(stored);
        return Object.assign({}, defaults, saved);
    } catch (e) {
        console.error(e);
        return Object.assign({}, defaults);
    }
};

export const AccessTokenUtils = {
    getToken: () => getSaved().token,
    setToken: (token: string, expires: number) => {
        const expiration = dayjs().add(expires, 'seconds').valueOf();
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({token, expiration}));
    },
    getExpiration: () => getSaved().expiration,
    clear: () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    },
};
